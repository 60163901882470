<script setup>
import { onMounted, ref } from 'vue'
import { useToast } from 'primevue/usetoast'
import { FilterMatchMode } from 'primevue/api'
import MotivoService from '../../../services/motivo.service'
import Swal from 'sweetalert2'
import { error } from '../../../../../../../libs/mensajes'

const toast = useToast()
const _MotivoService = ref(new MotivoService())
const modalMotivo = ref(false)
const listMotivos = ref([])
const motivo = ref({})
const submitted = ref(false)
const isEdit = ref(false)
const filters = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS }
})

// Obtener los motivos
const fetchMotivos = async () => {
  try {
    const { status, data } = await _MotivoService.value.listarMotivos()
    if (status === 200) {
      listMotivos.value = data
    }
  } catch (error) {
    console.error(error.message)
  }
}

// Abrir el modal
const openModalMotivo = (item) => {
  modalMotivo.value = true
  submitted.value = false

  if (item) {
    motivo.value = { ...item }
    isEdit.value = true
  } else {
    motivo.value = {
      id: null,
      nombre: '',
      estado: true
    }
    isEdit.value = false
  }
}

// Cerrar el modal
const hideModalMotivo = () => {
  modalMotivo.value = false
  submitted.value = false
}

// Crear un motivo
const createMotivo = async () => {
  try {
    const { status } = await _MotivoService.value.crearMotivo(motivo.value)
    if (status === 200) {
      toast.add({ severity: 'success', summary: 'Exito', detail: 'Motivo creado exitosamente', life: 3000 })
      await fetchMotivos()
      hideModalMotivo()
      modalMotivo.value = false
      motivo.value = {}
    }
  } catch (error) {
    console.error(error.message)
  }
}

// Actualizar un motivo
const updateMotivo = async () => {
  try {
    const { status } = await _MotivoService.value.editarMotivo(motivo.value.id, motivo.value)
    if (status === 200) {
      toast.add({ severity: 'success', summary: 'Exito', detail: 'Motivo actualizado exitosamente', life: 3000 })
      await fetchMotivos()
      hideModalMotivo()
      modalMotivo.value = false
      motivo.value = {}
    }
  } catch (error) {
    console.error(error.message)
  }
}

// Eliminar un motivo
const deleteMotivo = async (id) => {
  try {
    if (id) {
      Swal.fire({
        icon: 'warning',
        title: 'Advertencia',
        text: 'Desea eliminar el motivo?, esta acción no se puede revertir.',
        showDenyButton: true,
        denyButtonText: 'No, Cancelar',
        confirmButtonText: 'Si, Eliminar'
      }).then((result) => {
        if (result.isConfirmed) {
          _MotivoService.value.eliminarMotivo(id).then(({ status }) => {
            if (status !== 200) return

            toast.add({ severity: 'success', summary: 'Exito', detail: 'Motivo eliminado exitosamente', life: 3000 })
            fetchMotivos()
            hideModalMotivo()
            modalMotivo.value = false
            motivo.value = {}
          }).catch((err) => {
            console.error(err.message)
            if (err.response.status) {
              error(err.response.data.message)
            }
          })
        }
      })
    } else {
      toast.add({ severity: 'error', summary: 'Error', detail: 'No se ha podido eliminar el motivo', life: 3000 })
    }
  } catch (error) {
    console.error(error.message)
  }
}

// Guardar o actualizar un motivo
const onSubmit = async () => {
  submitted.value = true
  if (isEdit.value && motivo.value.id) {
    await updateMotivo()
  } else {
    await createMotivo()
  }
}

// Hook onMounted
onMounted(() => {
  fetchMotivos()
})

</script>

<template>
  <Toast />
  <Toolbar class="box mt-4 py-2 px-4">
    <template #start>
      <back-button />
    </template>
    <template #end>
      <Button
        label="Agregar"
        icon="pi pi-plus"
        size="small"
        severity="success"
        @click="openModalMotivo(null)"
      />
    </template>
  </Toolbar>

  <!-- Listado de los motivos -->
  <div class="box mt-4 intro-x">
    <DataTable
      showGridlines
      ref="dt"
      :value="listMotivos"
      class="p-datatable-sm p-4 text-sm"
      dataKey="id"
      :paginator="true"
      :rows="10"
      :filters="filters"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[5,10,25,50]"
      currentPageReportTemplate="Mostrando registros del {first} al {last} de un total de {totalRecords} registros"
      responsiveLayout="scroll"
    >
      <template #header>
        <div class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
          <h3 class="p-mb-2 p-m-md-0 p-as-md-center text-lg">Listado de Motivos</h3>
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText v-model="filters['global'].value" placeholder="Buscar..." />
          </span>
        </div>
      </template>

      <!-- Columnas -->
      <Column field="nombre" header="Nombre" :sortable="true" style="min-width:16rem"></Column>
      <Column field="status" header="Estado" :sortable="true" style="min-width:12rem">
        <template #body="{ data }">
          <span :class="'bank-badge px-2 py-1 text-xs font-bold leading-none text-white rounded ' + (data.estado === true ? 'bg-green-700' : 'bg-red-700')">{{data.estado === true ? 'Activo' : 'Inactivo'}}</span>
        </template>
      </Column>
      <Column :exportable="false" header="Acciones" style="text-align: center">
        <template #body="{ data }">
          <Button icon="pi pi-pencil" class="p-button-rounded p-button-warning p-mr-2" @click="openModalMotivo(data)" />
          <Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="deleteMotivo(data.id)" />
        </template>
      </Column>
    </DataTable>
  </div>

  <!-- Modal formulario motivos -->
  <Dialog
    v-model:visible="modalMotivo"
    :style="{width: '450px'}"
    :header="`${isEdit ? 'Editar' : 'Agregar'} Motivo`"
    :modal="true"
    class="p-fluid"
  >
    <div class="p-field">
      <label for="name">Nombre</label>
      <InputText
        id="name"
        v-model="motivo.nombre"
        required="true"
        autofocus
        :class="{'p-invalid': submitted && !motivo.nombre}"
        class="p-fluid"
      />
      <small class="p-error" v-if="submitted && !motivo.nombre">El nombre es requerido.</small>
    </div>
    <div class="p-field">
      <label for="status">Estado</label>
      <InputSwitch v-model="motivo.estado" id="status" class="p-d-block"/>
    </div>

    <template #footer class="p-text-bold">
      <Button label="Cancelar" icon="pi pi-times" class="p-button-danger p-button-sm" @click="hideModalMotivo"/>
      <Button label="Guardar" icon="pi pi-check" class="p-button-success p-button-sm" @click="onSubmit" />
    </template>
  </Dialog>
</template>

<style scoped>

</style>
