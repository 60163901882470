import http from '@/libs/http'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_SOLICITUD_PERMISOS

export default class MotivoService {
  getMotivo (id) {
    return http.get(`${baseUrl}/motivo/find/${id}`, {
      headers: {
        loading: true
      }
    })
  }

  listarMotivos () {
    return http.get(`${baseUrl}/motivo/listar`, {
      headers: {
        loading: true
      }
    })
  }

  crearMotivo (body) {
    return http.post(`${baseUrl}/motivo/crear`, body, {
      headers: {
        loading: true
      }
    })
  }

  editarMotivo (id, body) {
    return http.put(`${baseUrl}/motivo/editar/${id}`, body, {
      headers: {
        loading: true
      }
    })
  }

  eliminarMotivo (id) {
    return http.delete(`${baseUrl}/motivo/eliminar/${id}`, {
      headers: {
        loading: true
      }
    })
  }
}
